.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.parallax-bg {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity here */
  z-index: 1; /* Ensure the overlay is above the background image but below the content */
}

.content-container {
  height: 100vh;
  width: 80%;
  overflow: auto;
  padding-top: 64px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 600px) {
  .content-container {
    width: 100%;
    padding-top: 50px;
  }

  .MuiToolbar-root {
    min-height: 50px !important; /* Override MUI's default toolbar height */
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
